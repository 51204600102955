@use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl;
@use "~@otto-ec/global-frontend-settings/scss/variables" as variables;
@use "~@otto-ec/global-frontend-settings/gfs" as gfs;
@use "../variables" as vars;
@use "../mixins" as mixins;


.nav_mobile-list-tile {
  position: relative;
  text-decoration: none;
  background-color: white;
  cursor: pointer;

  line-height: vars.$navi-elem-height-standard;

  /*          */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include mixins.font-size(variables.$font75);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: none;
  height: 48px;
  padding: 0 6px 0 8px;

  &__title {
    color: pl.$pl_color-black100;
  }

  @include gfs.breakpoint-raw(vars.$nav-small-medium-width) {
    @include mixins.font-size(variables.$font100);
    height: 64px;
  }

  &__image {
    &--fallback {
      background-color: white;
    }
    border-radius: 2px;
    width: 36px;
    height: 36px;
    background-color: pl.$pl_color-grey25;
    @include gfs.breakpoint-raw(vars.$nav-small-medium-width) {
      width: 48px;
      height: 48px;
    }
  }

}