@use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl;
@use "~@otto-ec/global-frontend-settings/scss/variables" as variables;
@use "~@otto-ec/global-frontend-settings/gfs" as gfs;
@use "../variables" as vars;
@use "../mixins" as mixins;


.nav_mobile-list-item {
  padding: 0 16px;
  @include mixins.font-size(variables.$font100);
  text-decoration: none;
  /**
 *
 *
 *
 *
 */
  color: pl.$pl_color-grey100;
  background-color: white;
  display: flex;
  align-items: center;
  cursor: pointer;

  height: vars.$navi-elem-height-image-row;
  line-height: vars.$navi-elem-height-image-row;

  border-bottom: 1px solid pl.$pl_color-grey100;

  &__image {
    border-radius: 4px;
    width: 36px;
    height: 36px;
    background-color: pl.$pl_color-grey25;

    &--fallback {
      background-color: pl.$pl_color-white100;
    }
  }

  &__title {
    padding: 0 36px 0 16px;
    flex: 1;

    color: pl.$pl_color-black100;

    /*          */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__arrow {
    margin-left: 12px;
  }

  &:has(+ .nav_sheet-cluster-title),
  &:has(+ .nav_menu-sheet--submenu:last-child),
  &:has(+ .nav_menu-sheet--submenu + .nav_sheet-cluster-title),
  &:last-child {
    border: none !important;
  }
}