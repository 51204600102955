/**
 *
 *
 */

$oc-base-breakpoint-l: var(--oc-base-breakpoint-l, 48em);
$oc-base-breakpoint-m: var(--oc-base-breakpoint-m, 28em);
$oc-base-breakpoint-s: var(--oc-base-breakpoint-s, 22.5em);
$oc-base-breakpoint-xl: var(--oc-base-breakpoint-xl, 62em);
$oc-base-breakpoint-xxl: var(--oc-base-breakpoint-xxl, 77em);
$oc-base-color-black-100: var(--oc-base-color-black-100, #212121);
$oc-base-color-black-lighten-10: var(--oc-base-color-black-lighten-10, #3a3a3a);
$oc-base-color-black-lighten-20: var(--oc-base-color-black-lighten-20, #545454);
$oc-base-color-black-lighten-30: var(--oc-base-color-black-lighten-30, #6d6d6d);
$oc-base-color-black-lighten-45: var(--oc-base-color-black-lighten-45, #949494);
$oc-base-color-black-lighten-61: var(--oc-base-color-black-lighten-61, #bdbdbd);
$oc-base-color-black-lighten-67: var(--oc-base-color-black-lighten-67, #cccccc);
$oc-base-color-black-lighten-71: var(--oc-base-color-black-lighten-71, #d6d6d6);
$oc-base-color-black-lighten-77: var(--oc-base-color-black-lighten-77, #e5e5e5);
$oc-base-color-black-lighten-81: var(--oc-base-color-black-lighten-81, #f0f0f0);
$oc-base-color-black-lighten-84: var(--oc-base-color-black-lighten-84, #f7f7f7);
$oc-base-color-black-opacity-0: var(--oc-base-color-black-opacity-0, rgba(33, 33, 33, 0));
$oc-base-color-black-opacity-10: var(--oc-base-color-black-opacity-10, rgba(33, 33, 33, 0.1));
$oc-base-color-black-opacity-3: var(--oc-base-color-black-opacity-3, rgba(33, 33, 33, 0.03));
$oc-base-color-black-opacity-40: var(--oc-base-color-black-opacity-40, rgba(33, 33, 33, 0.4));
$oc-base-color-black-opacity-5: var(--oc-base-color-black-opacity-5, rgba(33, 33, 33, 0.05));
$oc-base-color-black-opacity-50: var(--oc-base-color-black-opacity-50, rgba(33, 33, 33, 0.5));
$oc-base-color-black-opacity-70: var(--oc-base-color-black-opacity-70, rgba(33, 33, 33, 0.7));
$oc-base-color-blue-100: var(--oc-base-color-blue-100, #006fc5);
$oc-base-color-blue-25: var(--oc-base-color-blue-25, #def1ff);
$oc-base-color-blue-50: var(--oc-base-color-blue-50, #abdbff);
$oc-base-color-blue-darken-10: var(--oc-base-color-blue-darken-10, #005292);
$oc-base-color-blue-darken-20: var(--oc-base-color-blue-darken-20, #00365f);
$oc-base-color-blue-lighten-10: var(--oc-base-color-blue-lighten-10, #008cf8);
$oc-base-color-blue-lighten-25: var(--oc-base-color-blue-lighten-25, #45aeff);
$oc-base-color-blue-lighten-35: var(--oc-base-color-blue-lighten-35, #78c4ff);
$oc-base-color-blue-lighten-45: var(--oc-base-color-blue-lighten-45, #abdbff);
$oc-base-color-blue-lighten-55: var(--oc-base-color-blue-lighten-55, #def1ff);
$oc-base-color-corporate-color-spectrum-coral-100: var(--oc-base-color-corporate-color-spectrum-coral-100, #ff9196);
$oc-base-color-corporate-color-spectrum-coral-150: var(--oc-base-color-corporate-color-spectrum-coral-150, #d25a5f);
$oc-base-color-corporate-color-spectrum-coral-200: var(--oc-base-color-corporate-color-spectrum-coral-200, #bc3339);
$oc-base-color-corporate-color-spectrum-coral-25: var(--oc-base-color-corporate-color-spectrum-coral-25, #ffe2e2);
$oc-base-color-corporate-color-spectrum-coral-50: var(--oc-base-color-corporate-color-spectrum-coral-50, #ffafaf);
$oc-base-color-corporate-color-spectrum-cozy-pink-100: var(--oc-base-color-corporate-color-spectrum-cozy-pink-100, #ffbef5);
$oc-base-color-corporate-color-spectrum-cozy-pink-150: var(--oc-base-color-corporate-color-spectrum-cozy-pink-150, #f578cd);
$oc-base-color-corporate-color-spectrum-cozy-pink-50: var(--oc-base-color-corporate-color-spectrum-cozy-pink-50, #ffd7f5);
$oc-base-color-corporate-color-spectrum-deep-navy-100: var(--oc-base-color-corporate-color-spectrum-deep-navy-100, #1e5a78);
$oc-base-color-corporate-color-spectrum-deep-navy-150: var(--oc-base-color-corporate-color-spectrum-deep-navy-150, #14374b);
$oc-base-color-corporate-color-spectrum-deep-navy-50: var(--oc-base-color-corporate-color-spectrum-deep-navy-50, #287396);
$oc-base-color-corporate-color-spectrum-denim-100: var(--oc-base-color-corporate-color-spectrum-denim-100, #6ea0eb);
$oc-base-color-corporate-color-spectrum-denim-150: var(--oc-base-color-corporate-color-spectrum-denim-150, #374bc8);
$oc-base-color-corporate-color-spectrum-denim-25: var(--oc-base-color-corporate-color-spectrum-denim-25, #deedff);
$oc-base-color-corporate-color-spectrum-denim-50: var(--oc-base-color-corporate-color-spectrum-denim-50, #78b4ff);
$oc-base-color-corporate-color-spectrum-natural-beige-100: var(--oc-base-color-corporate-color-spectrum-natural-beige-100, #f5e6d7);
$oc-base-color-corporate-color-spectrum-natural-beige-150: var(--oc-base-color-corporate-color-spectrum-natural-beige-150, #dcbe96);
$oc-base-color-corporate-color-spectrum-natural-beige-50: var(--oc-base-color-corporate-color-spectrum-natural-beige-50, #fff5e6);
$oc-base-color-corporate-color-spectrum-positive-green-100: var(--oc-base-color-corporate-color-spectrum-positive-green-100, #64c8b9);
$oc-base-color-corporate-color-spectrum-positive-green-150: var(--oc-base-color-corporate-color-spectrum-positive-green-150, #00aa9b);
$oc-base-color-corporate-color-spectrum-positive-green-200: var(--oc-base-color-corporate-color-spectrum-positive-green-200, #007268);
$oc-base-color-corporate-color-spectrum-positive-green-25: var(--oc-base-color-corporate-color-spectrum-positive-green-25, #d1ede7);
$oc-base-color-corporate-color-spectrum-positive-green-50: var(--oc-base-color-corporate-color-spectrum-positive-green-50, #b4e1d7);
$oc-base-color-corporate-color-spectrum-sky-blue-100: var(--oc-base-color-corporate-color-spectrum-sky-blue-100, #dcf0fa);
$oc-base-color-corporate-color-spectrum-sky-blue-150: var(--oc-base-color-corporate-color-spectrum-sky-blue-150, #98c9f1);
$oc-base-color-corporate-color-spectrum-sky-blue-50: var(--oc-base-color-corporate-color-spectrum-sky-blue-50, #f0faff);
$oc-base-color-corporate-color-spectrum-sunny-yellow-100: var(--oc-base-color-corporate-color-spectrum-sunny-yellow-100, #fffaaf);
$oc-base-color-corporate-color-spectrum-sunny-yellow-125: var(--oc-base-color-corporate-color-spectrum-sunny-yellow-125, #ffeb91);
$oc-base-color-corporate-color-spectrum-sunny-yellow-150: var(--oc-base-color-corporate-color-spectrum-sunny-yellow-150, #ffdc73);
$oc-base-color-corporate-color-spectrum-sunny-yellow-50: var(--oc-base-color-corporate-color-spectrum-sunny-yellow-50, #ffffcd);
$oc-base-color-corporate-color-spectrum-warm-red-100: var(--oc-base-color-corporate-color-spectrum-warm-red-100, #8c2d4b);
$oc-base-color-corporate-color-spectrum-warm-red-150: var(--oc-base-color-corporate-color-spectrum-warm-red-150, #6e1937);
$oc-base-color-corporate-color-spectrum-warm-red-50: var(--oc-base-color-corporate-color-spectrum-warm-red-50, #c82864);
$oc-base-color-grey-100: var(--oc-base-color-grey-100, #e5e5e5);
$oc-base-color-grey-150: var(--oc-base-color-grey-150, #cccccc);
$oc-base-color-grey-200: var(--oc-base-color-grey-200, #949494);
$oc-base-color-grey-25: var(--oc-base-color-grey-25, #f0f0f0);
$oc-base-color-grey-400: var(--oc-base-color-grey-400, #6d6d6d);
$oc-base-color-otto-red-100: var(--oc-base-color-otto-red-100, #f00020);
$oc-base-color-otto-red-125: var(--oc-base-color-otto-red-125, #dc001d);
$oc-base-color-otto-red-darken-10: var(--oc-base-color-otto-red-darken-10, #bd0019);
$oc-base-color-otto-red-darken-20: var(--oc-base-color-otto-red-darken-20, #8a0012);
$oc-base-color-otto-red-darken-4: var(--oc-base-color-otto-red-darken-4, #dc001d);
$oc-base-color-sustainable-green-100: var(--oc-base-color-sustainable-green-100, #7ea87b);
$oc-base-color-white: var(--oc-base-color-white, #ffffff);
$oc-base-dimension-0: var(--oc-base-dimension-0, 0px);
$oc-base-dimension-12: var(--oc-base-dimension-12, 12px);
$oc-base-dimension-16: var(--oc-base-dimension-16, 16px);
$oc-base-dimension-2: var(--oc-base-dimension-2, 2px);
$oc-base-dimension-24: var(--oc-base-dimension-24, 24px);
$oc-base-dimension-32: var(--oc-base-dimension-32, 32px);
$oc-base-dimension-4: var(--oc-base-dimension-4, 4px);
$oc-base-dimension-40: var(--oc-base-dimension-40, 40px);
$oc-base-dimension-48: var(--oc-base-dimension-48, 48px);
$oc-base-dimension-56: var(--oc-base-dimension-56, 56px);
$oc-base-dimension-64: var(--oc-base-dimension-64, 64px);
$oc-base-dimension-72: var(--oc-base-dimension-72, 72px);
$oc-base-dimension-8: var(--oc-base-dimension-8, 8px);
$oc-base-dimension-80: var(--oc-base-dimension-80, 80px);
$oc-base-dimension-relative-0: var(--oc-base-dimension-relative-0, 0rem);
$oc-base-dimension-relative-12: var(--oc-base-dimension-relative-12, 0.75rem);
$oc-base-dimension-relative-16: var(--oc-base-dimension-relative-16, 1rem);
$oc-base-dimension-relative-2: var(--oc-base-dimension-relative-2, 0.125rem);
$oc-base-dimension-relative-24: var(--oc-base-dimension-relative-24, 1.5rem);
$oc-base-dimension-relative-32: var(--oc-base-dimension-relative-32, 2rem);
$oc-base-dimension-relative-4: var(--oc-base-dimension-relative-4, 0.25rem);
$oc-base-dimension-relative-40: var(--oc-base-dimension-relative-40, 2.5rem);
$oc-base-dimension-relative-48: var(--oc-base-dimension-relative-48, 3rem);
$oc-base-dimension-relative-56: var(--oc-base-dimension-relative-56, 3.5rem);
$oc-base-dimension-relative-64: var(--oc-base-dimension-relative-64, 4rem);
$oc-base-dimension-relative-72: var(--oc-base-dimension-relative-72, 4.5rem);
$oc-base-dimension-relative-8: var(--oc-base-dimension-relative-8, 0.5rem);
$oc-base-dimension-relative-80: var(--oc-base-dimension-relative-80, 5rem);
$oc-base-font-line-height-100: var(--oc-base-font-line-height-100, 1rem);
$oc-base-font-line-height-125: var(--oc-base-font-line-height-125, 1.25rem);
$oc-base-font-line-height-150: var(--oc-base-font-line-height-150, 1.5rem);
$oc-base-font-line-height-175: var(--oc-base-font-line-height-175, 1.75rem);
$oc-base-font-line-height-200: var(--oc-base-font-line-height-200, 2rem);
$oc-base-font-line-height-300: var(--oc-base-font-line-height-300, 3rem);
$oc-base-font-line-height-450: var(--oc-base-font-line-height-450, 4.5rem);
$oc-base-font-line-height-75: var(--oc-base-font-line-height-75, 0.75rem);
$oc-base-font-otto-play: var(--oc-base-font-otto-play, 'OTTOPlay', serif);
$oc-base-font-otto-sans: var(--oc-base-font-otto-sans, 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-base-font-size-100: var(--oc-base-font-size-100, 1rem);
$oc-base-font-size-125: var(--oc-base-font-size-125, 1.25rem);
$oc-base-font-size-150: var(--oc-base-font-size-150, 1.5rem);
$oc-base-font-size-200: var(--oc-base-font-size-200, 2rem);
$oc-base-font-size-300: var(--oc-base-font-size-300, 3rem);
$oc-base-font-size-62: var(--oc-base-font-size-62, 0.625rem);
$oc-base-font-size-75: var(--oc-base-font-size-75, 0.75rem);
$oc-base-font-size-87: var(--oc-base-font-size-87, 0.875rem);
$oc-base-font-weight-black: var(--oc-base-font-weight-black, 800);
$oc-base-font-weight-bold: var(--oc-base-font-weight-bold, 700);
$oc-base-font-weight-regular: var(--oc-base-font-weight-regular, 400);
$oc-base-shadow-100: var(--oc-base-shadow-100, drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.07)));
$oc-base-shadow-200: var(--oc-base-shadow-200, drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.10)));
$oc-base-shadow-300: var(--oc-base-shadow-300, drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.30)) drop-shadow(0px 9px 10px rgba(0, 0, 0, 0.10)));
$oc-semantic-border-radius-0: var(--oc-semantic-border-radius-0, 0px);
$oc-semantic-border-radius-100: var(--oc-semantic-border-radius-100, 8px);
$oc-semantic-border-radius-150: var(--oc-semantic-border-radius-150, 12px);
$oc-semantic-border-radius-200: var(--oc-semantic-border-radius-200, 16px);
$oc-semantic-border-radius-25: var(--oc-semantic-border-radius-25, 2px);
$oc-semantic-border-radius-300: var(--oc-semantic-border-radius-300, 24px);
$oc-semantic-border-radius-50: var(--oc-semantic-border-radius-50, 4px);
$oc-semantic-border-width-0: var(--oc-semantic-border-width-0, 0px);
$oc-semantic-border-width-100: var(--oc-semantic-border-width-100, 1px);
$oc-semantic-border-width-200: var(--oc-semantic-border-width-200, 2px);
$oc-semantic-border-width-400: var(--oc-semantic-border-width-400, 4px);
$oc-semantic-color-brand: var(--oc-semantic-color-brand, #f00020);
$oc-semantic-color-canvas-background: var(--oc-semantic-color-canvas-background, #ffffff);
$oc-semantic-color-disabled-background: var(--oc-semantic-color-disabled-background, #e5e5e5);
$oc-semantic-color-disabled-border: var(--oc-semantic-color-disabled-border, #cccccc);
$oc-semantic-color-disabled-overlay: var(--oc-semantic-color-disabled-overlay, rgba(214, 214, 214, 0.64));
$oc-semantic-color-disabled-text: var(--oc-semantic-color-disabled-text, #949494);
$oc-semantic-color-error-100: var(--oc-semantic-color-error-100, #bc3339);
$oc-semantic-color-error-50: var(--oc-semantic-color-error-50, #ffe2e2);
$oc-semantic-color-error-75: var(--oc-semantic-color-error-75, #ff9196);
$oc-semantic-color-frame-background: var(--oc-semantic-color-frame-background, #f0f0f0);
$oc-semantic-color-hint-100: var(--oc-semantic-color-hint-100, #374bc8);
$oc-semantic-color-hint-50: var(--oc-semantic-color-hint-50, #deedff);
$oc-semantic-color-hint-75: var(--oc-semantic-color-hint-75, #78b4ff);
$oc-semantic-color-overlay-active: var(--oc-semantic-color-overlay-active, rgba(33, 33, 33, 0.1));
$oc-semantic-color-overlay-default: var(--oc-semantic-color-overlay-default, rgba(33, 33, 33, 0));
$oc-semantic-color-overlay-hover: var(--oc-semantic-color-overlay-hover, rgba(33, 33, 33, 0.03));
$oc-semantic-color-primary-background: var(--oc-semantic-color-primary-background, #dc001d);
$oc-semantic-color-sale-background: var(--oc-semantic-color-sale-background, #dc001d);
$oc-semantic-color-scrim: var(--oc-semantic-color-scrim, rgba(33, 33, 33, 0.4));
$oc-semantic-color-secondary-background: var(--oc-semantic-color-secondary-background, #def1ff);
$oc-semantic-color-success-100: var(--oc-semantic-color-success-100, #007268);
$oc-semantic-color-success-50: var(--oc-semantic-color-success-50, #d1ede7);
$oc-semantic-color-success-75: var(--oc-semantic-color-success-75, #64c8b9);
$oc-semantic-color-sustainable: var(--oc-semantic-color-sustainable, #7ea87b);
$oc-semantic-color-text-default: var(--oc-semantic-color-text-default, #212121);
$oc-semantic-color-text-interactive: var(--oc-semantic-color-text-interactive, #006fc5);
$oc-semantic-color-text-inverted: var(--oc-semantic-color-text-inverted, #ffffff);
$oc-semantic-color-text-sale: var(--oc-semantic-color-text-sale, #dc001d);
$oc-semantic-color-text-secondary: var(--oc-semantic-color-text-secondary, #6d6d6d);
$oc-semantic-color-warning-100: var(--oc-semantic-color-warning-100, #ffdc73);
$oc-semantic-color-warning-50: var(--oc-semantic-color-warning-50, #fffaaf);
$oc-semantic-color-warning-75: var(--oc-semantic-color-warning-75, #ffeb91);
$oc-semantic-focus-outline-color: var(--oc-semantic-focus-outline-color, #008cf8);
$oc-semantic-focus-outline-offset: var(--oc-semantic-focus-outline-offset, 2px);
$oc-semantic-focus-outline-radius-100: var(--oc-semantic-focus-outline-radius-100, 10px);
$oc-semantic-focus-outline-radius-12: var(--oc-semantic-focus-outline-radius-12, 2px);
$oc-semantic-focus-outline-radius-200: var(--oc-semantic-focus-outline-radius-200, 18px);
$oc-semantic-focus-outline-radius-25: var(--oc-semantic-focus-outline-radius-25, 4px);
$oc-semantic-focus-outline-radius-50: var(--oc-semantic-focus-outline-radius-50, 6px);
$oc-semantic-focus-outline-style: var(--oc-semantic-focus-outline-style, solid);
$oc-semantic-focus-outline-width: var(--oc-semantic-focus-outline-width, 2px);
$oc-semantic-font-copy-100: var(--oc-semantic-font-copy-100, 400 0.875rem/1.5rem 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-copy-125: var(--oc-semantic-font-copy-125, 400 1rem/1.5rem 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-copy-50: var(--oc-semantic-font-copy-50, 400 0.625rem/1rem 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-copy-75: var(--oc-semantic-font-copy-75, 400 0.75rem/1rem 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-copy-font-family: var(--oc-semantic-font-copy-font-family, 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-copy-font-weight: var(--oc-semantic-font-copy-font-weight, 400);
$oc-semantic-font-display-100: var(--oc-semantic-font-display-100, 800 2rem/3rem 'OTTOPlay', serif);
$oc-semantic-font-display-200: var(--oc-semantic-font-display-200, 800 3rem/4.5rem 'OTTOPlay', serif);
$oc-semantic-font-display-font-family: var(--oc-semantic-font-display-font-family, 'OTTOPlay', serif);
$oc-semantic-font-display-font-weight: var(--oc-semantic-font-display-font-weight, 800);
$oc-semantic-font-headline-10: var(--oc-semantic-font-headline-10, 700 0.625rem/0.75rem 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-headline-100: var(--oc-semantic-font-headline-100, 700 1rem/1.5rem 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-headline-200: var(--oc-semantic-font-headline-200, 700 1.25rem/1.75rem 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-headline-25: var(--oc-semantic-font-headline-25, 700 0.75rem/1rem 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-headline-300: var(--oc-semantic-font-headline-300, 700 1.5rem/2rem 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-headline-50: var(--oc-semantic-font-headline-50, 700 0.875rem/1.25rem 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-headline-font-family: var(--oc-semantic-font-headline-font-family, 'OTTOSans', Arial, Helvetica, sans-serif);
$oc-semantic-font-headline-font-weight: var(--oc-semantic-font-headline-font-weight, 700);
$oc-semantic-spacing-0: var(--oc-semantic-spacing-0, 0px);
$oc-semantic-spacing-100: var(--oc-semantic-spacing-100, 16px);
$oc-semantic-spacing-12: var(--oc-semantic-spacing-12, 2px);
$oc-semantic-spacing-150: var(--oc-semantic-spacing-150, 24px);
$oc-semantic-spacing-200: var(--oc-semantic-spacing-200, 32px);
$oc-semantic-spacing-25: var(--oc-semantic-spacing-25, 4px);
$oc-semantic-spacing-50: var(--oc-semantic-spacing-50, 8px);
$oc-semantic-spacing-75: var(--oc-semantic-spacing-75, 12px);
$oc-semantic-spacing-cinema-gap: var(--oc-semantic-spacing-cinema-gap, 16px);
$oc-semantic-spacing-content-grid-gap: var(--oc-semantic-spacing-content-grid-gap, 16px);
$oc-semantic-spacing-content-grid-outer: var(--oc-semantic-spacing-content-grid-outer, 16px);
$oc-semantic-spacing-default-between-blocks: var(--oc-semantic-spacing-default-between-blocks, 4px);
$oc-semantic-spacing-form-checkbox-radio-button-horizontal: var(--oc-semantic-spacing-form-checkbox-radio-button-horizontal, 24px);
$oc-semantic-spacing-form-checkbox-radio-button-vertical: var(--oc-semantic-spacing-form-checkbox-radio-button-vertical, 24px);
$oc-semantic-spacing-form-hint-vertical: var(--oc-semantic-spacing-form-hint-vertical, 4px);
$oc-semantic-spacing-form-vertical: var(--oc-semantic-spacing-form-vertical, 24px);
$oc-semantic-spacing-lane-grid-gap: var(--oc-semantic-spacing-lane-grid-gap, 4px);
$oc-semantic-spacing-lane-grid-outer: var(--oc-semantic-spacing-lane-grid-outer, 0px);