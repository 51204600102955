@use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl;
@use "../mixins" as mixins;

.nav_menu-toggle-button {
  $self: &;
  $fontIcon: (font-size: 30px, line-height: 20px);
  $fontIconActive: (font-size: 28px, line-height: 20px);
  $fontTitle: (font-size: 9px, line-height: 10px);

  cursor: pointer;
  position: relative;
  display: block !important;
  height: 40px;
  margin: 5px 0 0;
  text-align: center;
  width: 40px;

  /*                */
  z-index: 9000;

  &__icon, &__title-open, &__title-closed {
    margin: 0;
  }

  &__icon {
    font-family: OttoIcons, Arial, Helvetica, sans-serif;
    @include mixins.font-size($fontIcon);
    padding-top: 4px;

    &:after {
      content: "=";
    }
  }

  &__title-open, &__title-closed {
    @include mixins.font-size($fontTitle);
  }

  /*               */
  &__title-open {
    display: none;
  }

  &--opened {
    #{$self}__icon {
      @include mixins.font-size($fontIconActive);
      padding-top: 3px;
      padding-bottom: 1px;

      &:after {
        content: "x";
      }
    }

    /*               */
    #{$self}__title-closed {
      display: none;
    }

    /*               */
    #{$self}__title-open {
      display: block;
    }
  }

  /*                                                        */
  /*                                                   */
  .find_header & {
    color: pl.$pl_color-grey400;
    margin: 0;
    /*             */
    z-index: 1;
    width: auto;
    height: auto;

    #{$self}__icon {
      font-size: 24px;
      line-height: 1.16667em;
      padding-top: 0;

      &:after {
        content: "\e921";
      }
    }

    #{$self}__title-open, #{$self}__title-closed {
      font-size: 10px;
      line-height: 1em;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--opened {
      #{$self}__icon {
        line-height: 1.14em;
        &:after {
          content: "x";
        }
      }
    }
  }

  .find_header.find_header--reduced & {
    #{$self}__icon {
      line-height: 1.6em;
    }

    #{$self}__title-open, #{$self}__title-closed {
      display: none;
    }
  }
}
