@use "@otto-ec/design-tokens/tokens";

@use "~@otto-ec/global-frontend-settings/gfs" as gfs;

.nav_top-brands {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  padding: tokens.$oc-semantic-spacing-50 tokens.$oc-semantic-spacing-100 tokens.$oc-semantic-spacing-150;

  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    max-width: 1100px;
  }

  &__card {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    /*                                           */
    min-width: 21%;
    max-width: 120px;
    line-height: 0;
    border-radius: tokens.$oc-semantic-border-radius-200;
    height: 64px;
    background-color: white;
    padding: 12px 16px;

    @include gfs.breakpoint(gfs.$breakpoint-l) {
      min-width: 0;
      padding: 16px 24px;
    }

  }
}
