@use "~@otto-ec/global-frontend-settings/scss/variables" as variables;
@use "../variables" as vars;
@use "../mixins" as mixins;

.nav_mobile-cluster {
  $self: &;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px;

  &__item {
    padding: 4px;
    width: 50%;
  }
}
