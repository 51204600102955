@use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl;
@use "~@otto-ec/global-frontend-settings/scss/variables" as variables;
@use "~@otto-ec/global-frontend-settings/gfs" as gfs;
@use "../variables" as vars;
@use "../mixins" as mixins;

@use "sass:math";

body {
  /*                                                                                                           */
  /*                        */
  --nav-menu-top-offset: 0px;
}

#nav_mobile-menu-wrapper {
  height: 0;
  display: block !important;
  @include gfs.breakpoint(gfs.$breakpoint-l) {
    display: none !important;
  }

  &.nav_mobile-menu-wrapper--open {
    height: calc(100vh - var(--nav-menu-top-offset));
  }
}

.nav_sheet__body {
  overflow-y: scroll;

  /*                                                                                 */
  /*                                                                 */
  padding-bottom: 150px;
}

.nav_sheet__header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background-color: #f0f0f0; /*             */

  .nav_sheet__back-button {
    cursor: pointer;
    flex: 0 0 78px;
  }

  .nav_sheet__back-button-arrow {
    font-family: OttoIcons, Arial, Helvetica, sans-serif;
    /*                  */
    font-size: 1.25rem;
    line-height: 1.3em;
    vertical-align: middle;
    padding-right: 5px;
  }

  .nav_sheet__back-button-text {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.4285714286em;
    vertical-align: middle;
  }

  .nav_sheet__placeholder {
    flex: 0 0 78px;
  }

  .nav_sheet__title {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
    color: #f00020; /*            */
    font-size: 1rem;
    line-height: 1rem;
    text-decoration: none;
    font-weight: bold;
  }
}

.nav_sheet-cluster-title {
  height: vars.$header-height;
  margin: 0;
  padding: 19px 16px 16px;
  @include mixins.font-size(variables.$font100);
  font-weight: bold;
}

.nav_menu-sheet {
  background-color: #f0f0f0;
  width: 100vw;
  height: calc(100vh - var(--nav-menu-top-offset));

  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;

  &--submenu {
    left: 100vw !important;
  }

  &--visible {
    display: flex !important;
  }
}

.nav_mobile-menu {
  width: 100vw;
  position: absolute;
  left: 0;

  height: calc(100vh - var(--nav-menu-top-offset));
  overflow: visible;

  background-color: pl.$pl_color-grey25;

  z-index: 11;

  &--visible {
    display: block !important;
  }
}
